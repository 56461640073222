import React from "react";
import { createTheme, ThemeProvider } from "@mui/material";
import "./index.css"
import AnimeDisplay from "../components/AnimeDisplay";




const theme = createTheme()


export default function IndexPage() {
  return (
    <ThemeProvider theme={theme} >
<AnimeDisplay />
  </ThemeProvider>
  )
}





